

























































import {
  Component, Prop, Watch, Vue,
} from 'vue-property-decorator';
import MyButton from '@/components/MyButton.vue';

export namespace myBoardForm {
  export interface form {
    id: number,
    name: string,
    order: number,
    url: string,
    read: string,
  }
}

@Component({
  components: {
    MyButton,
  },
})
export default class BoardForm extends Vue {
  @Prop() private text1!: string;

  @Prop() private text2!: string;

  @Prop({ default: true }) private isEdit!: boolean;

  @Prop() private board!: myBoard;

  formInput: myBoardForm.form = {
    id: 0,
    name: '',
    order: 0,
    url: '',
    read: 'PUBLIC',
  };

  @Watch('board')
  onChange() {
    this.formInput.id = this.board.ID;
    this.formInput.name = this.board.Name;
    this.formInput.order = this.board.Order;
    this.formInput.url = this.board.URL;
    this.formInput.read = this.board.ReadPermission;
  }
}
