

























import { Component, Vue } from 'vue-property-decorator';
import MyButton from '@/components/MyButton.vue';
import { apireq } from '@/utils/apiRequest';
import BoardForm, { myBoardForm } from '@/components/BoardForm.vue';

@Component({
  components: {
    MyButton,
    BoardForm,
  },
})
export default class ManageBoards extends Vue {
  boards: myBoard[] = [];

  showNewBoard: boolean = false;

  activeBoard: myBoard = {
    CreatedAt: '',
    ID: 0,
    Name: '',
    Order: 0,
    ReadPermission: '',
    UpdatedAt: '',
    URL: '',
  };

  createBoard(formInput: myBoardForm.form) {
    apireq('post', '/admin/board', formInput)
      .then((res) => {
        if (!res.data.data) return;

        const board: myBoard = res.data.data;
        this.boards.push(board);
      });
  }

  changeEditInput(board: myBoard) {
    this.activeBoard = board;
  }

  updateBoard(formInput: myBoardForm.form) {
    apireq('put', '/admin/board', formInput)
      .then((res) => {
        if (!res.data.data) return;

        const editBoard: myBoard = res.data.data;
        const item = this.boards.find(board => board.ID === formInput.id);
        if (item) this.boards.splice(this.boards.indexOf(item), 1, editBoard);
      });
  }

  deleteBoard(boardId: number) {
    // eslint-disable-next-line no-restricted-globals,no-alert
    if (!confirm('정말로 삭제하시겠습니까?')) return;

    apireq('delete', '/admin/board', {
      id: boardId,
    })
      .then((res) => {
        if (!res.data.data) return;

        const item = this.boards.find(board => board.ID === boardId);
        if (item) this.boards.splice(this.boards.indexOf(item), 1);
        [this.activeBoard] = this.boards;
      });
  }

  created() {
    apireq('get', '/board')
      .then((res) => {
        this.boards = res.data.data;
        [this.activeBoard] = this.boards;
      });
  }
}
